import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'

const DpaPage = () => (
  <Layout
    path="/security"
    title="Data Processing Addendum - Brentiv - Rental Software"
  >
    <div className="legal-page dpa-page">
      <SmallHeader title="Data Processing Addendum" />
      <div className="row-spaced legal-page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="container-padded">
                <p>
                  This Brentiv Data Processing Addendum ("<b>Addendum</b>")
                  amends the Brentiv Terms of Service (the <b>"Agreement</b>")
                  by and between you and Brentiv.
                </p>

                <h2 className="title">1. Definitions</h2>
                <p>
                  (a) “<b>Data Protection Legislation</b>” means European
                  Directives 95/46/EC and 2002/58/EC, and any legislation and/or
                  regulation implementing or made pursuant to them, or which
                  amends or replaces any of them (including the General Data
                  Protection Regulation, Regulation (EU) 2016/679);
                </p>

                <p>
                  (b) “<b>Data Processor</b>”, “<b>Data Subject</b>”, “
                  <b>Processor</b>”, “<b>Processing</b>”, “<b>Subprocessor</b>”,
                  and “<b>Supervisory Authority</b>” shall be interpreted in
                  accordance with applicable Data Protection Legislation;
                </p>

                <p>
                  (c) “<b>Personal Data</b>” as used in this Addendum means
                  information relating to an identifiable or identified Data
                  Subject who visits or engages in transactions through your
                  store (a “<b>Customer</b>”), which Brentiv Processes as a Data
                  Processor in the course of providing you with the Services.
                </p>

                <p>
                  (d) All other capitalized terms in this Addendum shall have
                  the same definition as in the Agreement.
                </p>

                <h2 className="title">2. Data Protection</h2>
                <p>
                  <strong>2.1.</strong> Where a Data Subject is located in the
                  European Economic Area, that Data Subject’s Personal Data will
                  be processed by Brentiv’s affiliate. As part of providing the
                  Services, this Personal Data may be transferred to other
                  regions, including to Canada and the United States. Such
                  transfers will be completed in compliance with relevant Data
                  Protection Legislation.
                </p>

                <p>
                  <strong>2.2.</strong> When Brentiv Processes Personal Data in
                  the course of providing the Services, Brentiv will:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>2.2.1.</strong> Process the Personal Data as a
                      Data Processor, only for the purpose of providing the
                      Services in accordance with documented instructions from
                      you (provided that such instructions are commensurate with
                      the functionalities of the Services), and as may
                      subsequently be agreed to by you. If Brentiv is required
                      by law to Process the Personal Data for any other purpose,
                      Brentiv will provide you with prior notice of this
                      requirement, unless Brentiv is prohibited by law from
                      providing such notice;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.2.</strong> notify you if, in Brentiv’s
                      opinion, your instruction for the processing of Personal
                      Data infringes applicable Data Protection Legislation;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.3.</strong> notify you promptly, to the extent
                      permitted by law, upon receiving an inquiry or complaint
                      from a Data Subject or Supervisory Authority relating to
                      Brentiv’s Processing of the Personal Data;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.4.</strong> implement and maintain appropriate
                      technical and organizational measures to protect the
                      Personal Data against unauthorized or unlawful processing
                      and against accidental loss, destruction, damage, theft,
                      alteration or disclosure. These measures shall be
                      appropriate to the harm which might result from any
                      unauthorized or unlawful processing, accidental loss,
                      destruction, damage or theft of Personal Data and
                      appropriate to the nature of the Personal Data which is to
                      be protected;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.5.</strong> provide you, upon request, with
                      up-to-date attestations, reports or extracts thereof where
                      available from a source charged with auditing Brentiv’s
                      data protection practices (e.g. external auditors,
                      internal audit, data protection auditors), or suitable
                      certifications, to enable you to assess compliance with
                      the terms of this Addendum;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.6.</strong> notify you promptly upon becoming
                      aware of and confirming any accidental, unauthorized, or
                      unlawful processing of, disclosure of, or access to the
                      Personal Data;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.7.</strong> ensure that its personnel who
                      access the Personal Data are subject to confidentiality
                      obligations that restrict their ability to disclose the
                      Customer Personal Data; and
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.8.</strong> upon termination of the Agreement,
                      Brentiv will promptly initiate its purge process to delete
                      or anonymize the Personal Data. If you request a copy of
                      such Personal Data within 60 days of termination, Brentiv
                      will provide you with a copy of such Personal Data.
                    </p>
                  </li>
                </ul>

                <p>
                  <strong>2.3.</strong> In the course of providing the
                  Services,you acknowledge and agree that Brentiv may use
                  Subprocessors to Process the Personal Data. Brentiv’s use of
                  any specific Subprocessor to process the Personal Data must be
                  in compliance with Data Protection Legislation and must be
                  governed by a contract between Brentiv and Subprocessor.
                </p>

                <h2 className="title">3. Miscellaneous</h2>
                <p>
                  <strong>3.1.</strong> In the event of any conflict or
                  inconsistency between the provisions of the Agreement and this
                  Addendum, the provisions of this Addendum shall prevail. For
                  avoidance of doubt and to the extent allowed by applicable
                  law, any and all liability under this Addendum, including
                  limitations thereof, will be governed by the relevant
                  provisions of the Agreement. You acknowledge and agree that
                  Brentiv may amend this Addendum from time to time by posting
                  the relevant amended and restated Addendum on Brentiv’s
                  website, available at{' '}
                  <a href="https://brentiv.com/dpa">https://brentiv.com/dpa</a>{' '}
                  and such amendments to the Addendum are effective as of the
                  date of posting. Your continued use of the Services after the
                  amended Addendum is posted to Brentiv’s website constitutes
                  your agreement to, and acceptance of, the amended Addendum. If
                  you do not agree to any changes to the Addendum, do not
                  continue to use the Service.
                </p>

                <p>
                  <strong>3.2.</strong> Save as specifically modified and
                  amended in this Addendum, all of the terms, provisions and
                  requirements contained in the Agreement shall remain in full
                  force and effect and govern this Addendum. If any provision of
                  the Addendum is held illegal or unenforceable in a judicial
                  proceeding, such provision shall be severed and shall be
                  inoperative, and the remainder of this Addendum shall remain
                  operative and binding on the parties.
                </p>

                <p>
                  <strong>3.3.</strong> The terms of this Addendum shall be
                  governed by and interpreted in accordance with the laws of the
                  Europe, without regard to principles of conflicts of laws. The
                  parties irrevocably and unconditionally submit to the
                  exclusive jurisdiction of the courts of the Europe with
                  respect to any dispute or claim arising out of or in
                  connection with this Addendum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </Layout>
)

export default DpaPage
